<template>
    <path d="m43.5 468.5c-58-58-58-151.9 0-209.9l225-225c44.6-44.6 117.3-44.6 161.9 0 44.7 44.7 44.8 117.2 0 161.9l-189.4 189.4c-31.4 31.4-82.5 31.4-113.9 0-31.5-31.5-31.5-82.5 0-113.9l160.5-160.5c4.7-4.7 12.3-4.7 17 0l17 17c4.7 4.7 4.7 12.3 0 17l-160.6 160.4c-12.7 12.7-12.7 33.3 0 46.1 12.7 12.7 33.4 12.7 46.1 0l189.5-189.5c26-26 26-68.1 0-94.1-25.9-25.9-68.1-25.9-94 0l-225 225c-39.2 39.3-39.3 102.8 0 142.1s102.9 39.3 142.2 0c65.3-65.3 130.6-130.7 195.9-196 4.7-4.7 12.3-4.7 17 0l17 17c4.7 4.7 4.7 12.3 0 17-65.3 65.3-130.6 130.6-195.9 196-58.3 58-152.2 58-210.3 0z"/>
</template>

<script>
export default {
    setup(props, { emit }) {
        emit('dimensions', { width: 464, height: 512 });
    },
    emits: ['dimensions'],
};
</script>
